.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 104px;
}

.wrapper {
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  header {
    justify-content: left;
    margin-left: 3.75rem;
    display: flex;
    font-size: 32px;
    width: 100%;
    max-width: 15rem;
    height: 100%;

    @media screen and (max-width: 1000px) {
      max-width: 10rem;
      img {
        width: 170px;
        height: auto;
      
      }
    }
  }

  nav {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;

    ol {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 0 20px 60px;
      gap: 30px;

      li {
        line-height: 20px;
        cursor: pointer;
        color: var(--grey-100)
      }
    }
  }
}