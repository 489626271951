.container {
  background-color: var(--grey-400);
  height: 2.25rem;
  display: flex;
  justify-content: center;

  .phone {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      height: 1.1rem;
    }
  }
}

.content {
  width: 100%;
  max-width: var(--max-width);
  text-align: right;

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;

  display: flex;
  gap: 1rem;
  justify-content: right;
  align-items: center;
  text-transform: uppercase;
}

.language {
  display: flex;
  align-items: center;
}