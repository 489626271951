.component {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .content {
    background-color: var(--grey-100);
    height: 3rem;
    background: linear-gradient(180deg, #3C3B3B 0%, #2D2D2D 100%);
    max-width: var(--max-width);
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: 970px){
    display: none;
  }
}