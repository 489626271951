.component {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;


  .icon {

    div {
      position: relative;
      top: 0;
      height: 1.8px;
      background-color: #fff;
      margin-bottom: 3.27px;
      transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
        0.3s ease right;
      border-radius: 2px;
    }

    div:first-child {
      transform-origin: 0;
      display: noen;
    }

    div:last-child {
      margin-bottom: 0;
      // transform-origin: 60px;
    }

    div:nth-child(2) {
      right: 0;
      width: 18px;
    }
  }

  &.open {
    .icon {
      div:first-child {
        top: -2px;
        transform: rotateZ(45deg);
        display: none;
      }

      div:last-child {
        top: -5px;
        right: 0px;
        transform: rotateZ(45deg);
        // transform-origin: 60px;
      }

      div:nth-child(2) {
        width: 20px;
        top: 0;
        right: 0px;
        transform: rotateZ(-45deg);
      }
    }
  }
}