.component {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // modal middle of the screen 
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s ease-in-out;

  .header {
    height: 3rem;
    display: flex;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: var(--grey-900);
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 2.5rem;
      height: 2.5rem;
      border: none;
      background: none;
    }
  }

  .container {
    max-height: 100%;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    border: 1px solid var(--grey-300);
    min-width: 10rem;
    max-width: calc(100vw - 2rem);

    background: var(--background-color);
    border-radius: 0.5rem;
    padding: 0 1.5rem 1.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 769px){
      min-width: 80%;
    }
  }

  .content {
    // scroll overflow
    overflow-y: hidden;
    max-height: 80vh;

    &.scroll {
      overflow-y: auto;
    }
  }
}

// animatioon key fade in from bottom
@keyframes fadeIn {
  0% {
    opacity: 0;
    // transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    // transform: translateY(0);
  }
}