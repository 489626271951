@import "/styles/variables.scss";

.login {
  padding: var(--page-padding);

  .error {
    color: red;
  }

  .registerButton {
    margin-top: 2rem;
    width: 100%;
    max-width: 22.5rem;

    @media screen and (max-width: $screen-md-min) {
      max-width: unset;
    }
  }


  .resetPassword {
    margin-top: 2rem;
  }
}