.component {
  position: relative;

  &.background {
    input {
      background: linear-gradient(180deg, #303030 0%, #494949 100%);
      border: none;
      border-radius: var(--border-radius);
      font-weight: 600;

      &::placeholder {
        color: #A8A8A8;
        font-style: italic;
      }
    }
  }

  &.lightBackground {
    svg {
      color: #000000;
    }

    path {
      stroke: #000000;
    }

    input {
      background: linear-gradient(180deg, #FFFFFF 0%, #C7C7C7 100%);
      border: none;
      border-radius: var(--border-radius);
      font-weight: 600;
      color: #000000;

      &::placeholder {
        color: #000000;
        font-style: italic;
      }
    }
  }

  &.border {
    input {
      background: #181818;
      border: 0.75px solid #7A7A7A;
      border-radius: var(--border-radius);
      font-weight: 400;

      &::placeholder {
        color: #C0C0C0;
      }

      &:focus {
        border: 0.75px solid #fff;
      }
    }
  }

  &.error {
    input {
      border: 0.75px solid red;
    }
  }

  input {
    height: 40px;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    font-size: 12.812px;
    line-height: 18px;
    color: #FFFFFF;

    &:focus {
      outline: none;
    }

    &:disabled {
      background: #181818;
      border: 0.75px solid #7A7A7A;
      color: #7A7A7A;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 1.125rem;
    transform: translateY(-50%);
  }

  &.small {
    input {
      height: 30px;
    }
  }

  &.tiny {
    input {
      height: 20px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &.hasIcon {
    input {
      padding-left: 2.5rem;
    }
  }

  &.center {
    input {
      text-align: center;
    }
  }

  .errorText {
    color: red;
    font-size: 11.9598px;
    margin-bottom: .5rem;
    margin-top: -.25rem;
  }

  label {
    font-weight: 600;
    font-size: 8px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #8F8F8F;
  }
}