.component {
  background: linear-gradient(180deg, #000000 0%, #3C3C3C 100%);
  position: fixed;
  top: 0;
  max-height: var(--mobile-menu-height);
  height: 100%;
  width: 100%;
  z-index: 1000;
  overflow: hidden;

  // animate max-height
  transition: max-height 0.2s ease-in-out;

  &.open {
    max-height: 100%;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--mobile-menu-height);
    // margin: 0 1rem;
    margin-left: 1rem;
    margin-right: 0.25rem;
  }

  .buttons {
    margin: 2rem;

    button {
      width: 100%;
    }
  }

  li {
    padding: 1rem 2rem;
  }

  .toggleIcon {
    width: 1.5rem;
    height: 1.5rem;

    svg {
      color: #fff;
      fill: #fff;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .menuContent {
    overflow-y: auto;
    height: calc(100% - var(--mobile-menu-height));
  }

  .phoneIcon {
    position: absolute;
    left: 4rem;

    svg {
      height: 1.25rem;
    }
  }
}