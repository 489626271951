.component {
  position: relative;

  label {
    margin-bottom: .25rem;
    display: inline-block;
  }

  input, select {
    margin-bottom: .5144rem;
  }

  button {
    margin-top: 0.25rem;
  }
}