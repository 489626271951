.component {
  .current {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  
    img {
      height: 14px;
      max-width: 30px;
      width: auto;
    }
  }

  .hasLabel {
    img {
      padding-left: .625rem;
    }
  }
  
  .countryWindow {
    p {
      padding: .5rem;
      margin-bottom: 1rem;
    }
  }
  
  .selectlist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 16rem;
    overflow-y: auto;
    gap: 0.5rem;
    border: 1px solid var(--grey-400);
    border-radius: 0.5rem;
    padding: 0.5rem;
  }

  .selected {
    background-color: var(--grey-400);
  }

  .country {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 0.75rem;
    width: 100%;

    &:hover {
      background-color: var(--grey-400);
    }

    img {
      height: 1.75rem;
      width: 1.75rem;
      // make it round
      border-radius: 50%;
    }
  }

  .button {
    margin-top: 1.5rem;
  }
}