.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: var(--max-width);
  width: 100%;
  
  display: flex;
  flex-direction: column;
  // // justify-content: space-between;
  min-height: calc(100vh - 36px);

  > main {
    flex-grow: 1;
    background-color: var(--background-color);
  }
}