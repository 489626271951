.component {
  flex-direction: column;
  height: 20px;
  position: relative;
  cursor: pointer;

  .isOpen {
    display: flex !important;
  }

  &.disabled {
    cursor: default;
    opacity: .6;
  }

  .dropdown {
    display: none;
    flex-direction: column;
    background-color: var(--grey-400);
    position: absolute;
    top: 1.75rem;
    min-width: 3rem;
    z-index: 1;
    border: 1px solid var(--grey-300);
    border-top: none;
    min-width: 100%;

    :hover {
      background-color: var(--grey-200)
    }

    >div {
      display: flex;
      align-items: center;
      padding: .25rem;
      cursor: pointer;
      justify-content: center;
    }
  }
}