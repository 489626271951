.container {
  height: 27.5rem;
  width: 100%;
  max-width: var(--max-width);
  background: linear-gradient(180deg, #2B2B2B 0%, #070707 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 0 3.75rem;
  padding-top: 5rem;

  .copyright {
    font-style: normal;
    font-weight: 400;
    font-size: 13.23px;
    line-height: 18px;
    display: flex;

    img {
      margin-bottom: 1rem;
    }
  }

  .social {
    display: flex;
    gap: .75rem;

    .social-icon {
      cursor: pointer;
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
    gap: 40px;
  }

  .navigationHeader {
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: right;

    ol {
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 10px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: .625rem;
  }

  li, .text {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
    color: #929292;
  }

  li {
    text-transform: uppercase;
  }

  @media screen and (max-width: 769px) {
    padding: 0 1.5rem;
    padding-top: 3rem;
    padding-bottom: 6rem;
    height: 100%;
    flex-direction: column;
    gap: 2rem;

    .navigation {
      flex-direction: column;
      gap: 2rem;
    }

    .navigationHeader {
      margin-bottom: 0;
    }

    .text {
      gap: 1rem;
    }

    .social {
      gap: 1rem;
    }
    
  }
}