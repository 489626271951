.component {
  display: flex;
  align-items: center;
  line-height: 12px;
  cursor: pointer;

  .account {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: .25rem;
  }

  .label {
    font-weight: 700;
    font-size: 7px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #A8A8A8;
  }

  .name {
    font-weight: 700;
    font-size: 8px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .icon {
    background: #3E3E3E;
    height: 1.6875rem;
    width: 1.6875rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-top: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  @media screen and (max-width: 970px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 1rem 2rem;

    .account {
      margin-right: 0;
      margin-left: .25rem;
      align-items: flex-start;

    }
  }
}