.button {
  background: linear-gradient(180deg, #F6A622 0%, #F87C18 100%);
  border: none;
  color: #000000;
  min-width: 79px;
  padding: 0 .625rem;
  height: 1.875rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;

  .icon {
    margin-right: .4375rem;
  }

  &.iconRight {
    flex-direction: row-reverse;
    .icon{
      margin-left: .4375rem;
      margin-right: 0;
    }
  }

  .arrow {
    margin-left: .4375rem;
    margin-bottom: 1px;
  }

  &.rounded {
    border-radius: var(--border-radius);
  }

  &.dark {
    background: #2D2D2D;
    color: #868686;

    .arrow {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.lightDark {
    background: linear-gradient(180deg, #676767 0%, #3C3C3C 100%);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
  }

  &.darkGradient {
    background: linear-gradient(180deg, #787878 -24.06%, #434343 75.94%);
    color: #FFFFFF;

    .arrow {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.medium {
    height: 2.25rem;
  }

  &.large {
    height: 3rem;
  }

  &.active,
  &.dark:hover {
    background: #474747;
    color: #FFFFFF;
  }

  &:disabled {
    background: #b4b4b4;
    color: #e4e4e4;
    cursor: not-allowed;
  }
}