.component {
  display: flex;
  justify-content: center;
  height: 3rem;
  margin: 3rem 0;

  &.small {
    height: 1.5rem;
    margin: 1.5rem 0;

    .spinner {
      width: 1.5rem;
      height: 1.5rem;
      border: 0.125rem solid #fff;
      border-top: 0.125rem solid #000;
    }
  }

 .spinner {
    width: 3rem;
    height: 3rem;
    border: 0.25rem solid #fff;
    border-top: 0.25rem solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
 } 
}